import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
/**
 * Represents 50-50 Component.
 * @author Harmeet
 */import React from 'react';
import FiftyFiftyCard from '../../molecules/FiftyFiftyCard';
import { imageRenditions, videoImageRenditions } from './config';
import urlSlug from 'url-slug';
var FiftyFifty = function FiftyFifty(_ref) {
  var _fiftyFiftyData$cta, _fiftyFiftyData$ctaSe, _fiftyFiftyData$sys, _fiftyFiftyData$sys2;
  var fiftyFiftyData = _ref.fiftyFiftyData;
  if (!fiftyFiftyData) {
    return false;
  }
  var variation = fiftyFiftyData.variation,
    topPadding = fiftyFiftyData.topPadding,
    bottomPadding = fiftyFiftyData.bottomPadding,
    anchorLink = fiftyFiftyData.anchorLink;
  var idVal = anchorLink ? urlSlug(anchorLink) : '';
  var className = "nva-50-50__card nva-50-50__card--".concat(variation === null || variation === void 0 ? void 0 : variation.toLowerCase(), " fifty-fifty-card row ").concat(variation);

  // top padding from contentful
  className += topPadding === null || topPadding === 'Default' ? '' : " padding-top-override-".concat(topPadding === null || topPadding === void 0 ? void 0 : topPadding.toLowerCase());

  // bottom padding from contentful
  className += bottomPadding === null || bottomPadding === 'Default' ? '' : " padding-bottom-override-".concat(bottomPadding === null || bottomPadding === void 0 ? void 0 : bottomPadding.toLowerCase());
  var text = (fiftyFiftyData === null || fiftyFiftyData === void 0 ? void 0 : fiftyFiftyData.shortText) || null;
  var bladeBackgroundImage = fiftyFiftyData.bladeBackgroundImage && fiftyFiftyData.bladeBackgroundImage.image && fiftyFiftyData.bladeBackgroundImage.image.file ? fiftyFiftyData.bladeBackgroundImage.image.file.url : fiftyFiftyData.bladeBackgroundImage && fiftyFiftyData.bladeBackgroundImage.image && fiftyFiftyData.bladeBackgroundImage.image.url;
  var ctas = [];
  if (fiftyFiftyData !== null && fiftyFiftyData !== void 0 && (_fiftyFiftyData$cta = fiftyFiftyData.cta) !== null && _fiftyFiftyData$cta !== void 0 && _fiftyFiftyData$cta.url) ctas.push(fiftyFiftyData.cta);
  if (fiftyFiftyData !== null && fiftyFiftyData !== void 0 && (_fiftyFiftyData$ctaSe = fiftyFiftyData.ctaSecond) !== null && _fiftyFiftyData$ctaSe !== void 0 && _fiftyFiftyData$ctaSe.url) ctas.push(fiftyFiftyData.ctaSecond);
  var dto = {
    className: className,
    ariaLabel: fiftyFiftyData.cta ? fiftyFiftyData.cta.label : null,
    fontColor: fiftyFiftyData.fontColor,
    headingText: fiftyFiftyData.headline,
    sideHeadline: fiftyFiftyData.sideHeadline,
    sideShortText: fiftyFiftyData.sideShortText,
    subHeadingText: fiftyFiftyData.subHeadline,
    categoryTitle: fiftyFiftyData === null || fiftyFiftyData === void 0 ? void 0 : fiftyFiftyData.categoryTitle,
    descriptionText: text,
    backgroundTheme: "".concat(fiftyFiftyData.backgroundTheme),
    overrideBackground: fiftyFiftyData.overrideBackground,
    imageSource: "".concat(bladeBackgroundImage),
    placeholderSrc: "".concat(bladeBackgroundImage),
    media: {
      image: fiftyFiftyData.bladeBackgroundImage,
      video: fiftyFiftyData.video || ''
    },
    alt: fiftyFiftyData.headline,
    cta: ctas,
    styleTypeAnchor: 'primary-anchor',
    styleType: 'fiftyFifty',
    classNameHeading: 'nva-50-50__heading fiftyFiftyHeading',
    classNameSubHeading: 'nva-50-50__sub-heading fiftyFiftySubHeading heading_four',
    classNamePara: 'nva-50-50__para fiftyFiftyPara',
    classNameAnchor: 'nva-50-50__anchor fiftyFiftyAnchor  cta_btn btn',
    dataAnalytics: {
      type: 'cta-50-50',
      value: fiftyFiftyData.headline,
      variation: variation
    },
    srcSetSetting: {
      imageRenditions: fiftyFiftyData.video ? videoImageRenditions : imageRenditions
    },
    entryId: fiftyFiftyData === null || fiftyFiftyData === void 0 ? void 0 : (_fiftyFiftyData$sys = fiftyFiftyData.sys) === null || _fiftyFiftyData$sys === void 0 ? void 0 : _fiftyFiftyData$sys.id
  };
  var attributes = {};
  if (idVal != '') attributes.id = idVal;
  return __jsx("div", _extends({
    className: "nva-50-50 organism fifty-fifty"
  }, attributes, {
    "data-entry-id": fiftyFiftyData === null || fiftyFiftyData === void 0 ? void 0 : (_fiftyFiftyData$sys2 = fiftyFiftyData.sys) === null || _fiftyFiftyData$sys2 === void 0 ? void 0 : _fiftyFiftyData$sys2.id
  }), __jsx(FiftyFiftyCard, dto));
};
export default FiftyFifty;